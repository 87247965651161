import { JSX, useEffect } from 'react'
import AnslutnaForetagForm from '@local/Views/AnslutnaForetag/AnslutnaForetagForm/AnslutnaForetagForm'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useAppDispatch } from '@local/Store/configureStore'
import { setShouldGetAnslutnaForetag } from '@local/Views/AnslutnaForetag/anslutnaForetagSlice'
import { IAnslutnaForetagContent } from '@local/Views/AnslutnaForetag/AnslutnaForetag.types'

const AnslutnaForetag = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const content = usePickEpiContent<IAnslutnaForetagContent>()

  useEffect(
    () => () => {
      dispatch(setShouldGetAnslutnaForetag(false))
    },
    [dispatch]
  )

  return <AnslutnaForetagForm content={content} />
}

export default AnslutnaForetag

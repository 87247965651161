import getConfig from '@local/Utils/getConfig'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from './prepareHeaders'

const { API_URL } = getConfig()

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders,
  }),
  tagTypes: [],
  endpoints: () => ({}),
})

import { baseApi } from '@local/services/API/baseApi'
import getConfig from '@local/Utils/getConfig'
import { IAnslutnaForetagApiModel } from '@local/Views/AnslutnaForetag/AnslutnaForetag.types'

const { PUBLIC_WEB_API_URL } = getConfig()

export const foretagApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAnslutetForetag: builder.query<IAnslutnaForetagApiModel, string>({
      query: (organisationsnummer) =>
        `${PUBLIC_WEB_API_URL}/workplace/search/membership-by-org-number?query=${organisationsnummer}`,
    }),
  }),
})

export const { useGetAnslutetForetagQuery } = foretagApi

import * as Yup from 'yup'

const toDigits = (numString: string) =>
  numString.replace(/\D/g, '').split('').map(Number)

export const validateOrganizationNumber = (
  organizationNumber: string
): boolean => {
  if (!organizationNumber) {
    return false
  }
  const digits = toDigits(organizationNumber)
  const checkDigit = digits[digits.length - 1]
  const total = digits
    .slice(0, -1)
    .reverse()
    .map((current: number, index: number) =>
      index % 2 === 0 ? current * 2 : current
    )
    .map((current: number) => (current > 9 ? current - 9 : current))
    .reduce(
      (current: number, accumulator: number) => current + accumulator,
      checkDigit
    )
  return total % 10 === 0
}

/**
 * The typings for Yup are missing some fields,
 * so extend it to include the needed fields
 */
interface TestContextExtended<T> extends Omit<Yup.TestContext, 'options'> {
  from: {
    schema: Yup.AnyObjectSchema
    value: T
  }[]
  options: {
    index: number
  }
  parent: T
}
export const getYupTestContext = <T>(
  testContext: Yup.TestContext
): TestContextExtended<T> => testContext as TestContextExtended<T>

export const getYupFormValues = <T>(
  testContext: Yup.TestContext
): {
  index: number
  formValues: T
} => {
  const context = getYupTestContext<T>(testContext)
  const index = context.options.index
  const formValues = context.from[context.from.length - 1].value

  return { index, formValues }
}

export const trimString = (str: string) => str.replace(/\s/g, '')

export const handleChangeWithTrim = (
  e: React.ChangeEvent<HTMLInputElement>,
  handleChange: (e: React.ChangeEvent<unknown>) => void
) => {
  e.currentTarget.value = trimString(e.currentTarget.value)

  handleChange(e)
}

import { either, isNil, isEmpty, includes } from 'ramda'

import { orgNummerRegex } from './regexes'

type MapToOutput<I, U = I> = (v: I) => U

export const checkNullUndefined: MapToOutput<unknown, boolean> = either(
  isEmpty,
  isNil
)

export const isValidOrgNumberFormat = (orgNr: string) => {
  const regex = orgNummerRegex
  return regex.test(orgNr)
}

export const isValidOrgNumberChecksum = (orgNr: string) => {
  const digits = orgNr.slice(0, -1).replace(/\D/g, '')
  let sum = 0

  digits.split('').forEach((digit, index) => {
    const val = parseInt(digit) * (index % 2 === 0 ? 2 : 1)
    val
      .toString()
      .split('')
      .forEach((digit2) => {
        sum += parseInt(digit2)
      })
  })

  const checksum = (Math.ceil(sum / 10) * 10 - sum) % 10

  const lastDigit = orgNr[orgNr.length - 1]

  return checksum.toString() === lastDigit
}

export const replaceEpiVariables = (
  text: string,
  variables: { [key: string]: string } = {}
) => {
  let result = text
  Object.keys(variables).forEach((key) => {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = result.replace(regex, variables[key])
  })
  return result
}

export const isLinkAnchor = (href: string): boolean => href.startsWith('#')

export const isLinkExternal = (href: string): boolean =>
  href.startsWith('http') ||
  href.startsWith('mailto:') ||
  href.startsWith('tel:')

const linkFileFormats = `
  doc
  docx
  odt
  xls
  xlsx
  xlt
  xltx
  ods
  pdf
`
  .trim()
  .split(/\s+/g)

export const isLinkFile = (href: string): boolean =>
  linkFileFormats.filter((s) => includes(s, href.slice(-4))).length !== 0

import { RootState } from '@local/Store/configureStore'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AnslutnaForetagState {
  shouldGetAnslutnaForetag: boolean
}

const initialState: AnslutnaForetagState = {
  shouldGetAnslutnaForetag: false,
}

export const anslutnaForetagSlice = createSlice({
  name: 'anslutnaForetag',
  initialState,
  reducers: {
    setShouldGetAnslutnaForetag: (state, action: PayloadAction<boolean>) => {
      state.shouldGetAnslutnaForetag = action.payload
    },
    resetAnslutnaForetagState: () => initialState,
  },
})

export const { setShouldGetAnslutnaForetag, resetAnslutnaForetagState } =
  anslutnaForetagSlice.actions

export const selectAnslutnaForetagState = (state: RootState) =>
  state.anslutnaForetag

export default anslutnaForetagSlice.reducer

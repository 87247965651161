import { JSX, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectAnslutnaForetagState } from '@local/Views/AnslutnaForetag/anslutnaForetagSlice'
import { useGetAnslutetForetagQuery } from '@local/services/API/foretagApi'
import { useFormikContext } from 'formik'
import { IAnslutnaForetagFormValues } from '@local/Views/AnslutnaForetag/AnslutnaForetagForm/AnslutnaForetagForm.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import ForetagAnslutet from '@local/Views/AnslutnaForetag/AnslutnaForetagForm/ForetagResultat/ForetagAnslutet'
import ForetagEjAnslutet from '@local/Views/AnslutnaForetag/AnslutnaForetagForm/ForetagResultat/ForetagEjAnslutet'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useTrackVirtualView } from '@trr/gtm-tracking'
import Spinner from '@local/Components/Spinner'
import Alert from '@local/Components/Alert'
import { IAnslutnaForetagContent } from '@local/Views/AnslutnaForetag/AnslutnaForetag.types'

const ForetagResultat = (): JSX.Element => {
  const { nagotGickFel } = usePickEpiContent<IAnslutnaForetagContent>()

  const { shouldGetAnslutnaForetag } = useSelector(selectAnslutnaForetagState)

  const { values } = useFormikContext<IAnslutnaForetagFormValues>()
  const shouldTrack = useIsFeatureEnabled('gtm.enabled.anslutna-foretag')
  const anslutetResultat = useTrackVirtualView('Anslutet företag')

  const {
    currentData: foretagData,
    isError,
    isFetching,
  } = useGetAnslutetForetagQuery(values.organisationsnummer, {
    skip: !shouldGetAnslutnaForetag,
  })
  useEffect(() => {
    if (shouldTrack && foretagData?.results) {
      anslutetResultat(
        foretagData?.results && foretagData.results.length > 0
          ? 'Anslutet'
          : 'Ej anslutet'
      )
    }
  }, [shouldTrack, foretagData, anslutetResultat])

  if (isFetching) {
    return <Spinner centered />
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    foretagData && (
      <>
        {foretagData.results.length > 0 && (
          <ForetagAnslutet result={foretagData.results[0]} />
        )}
        {foretagData.results.length <= 0 && <ForetagEjAnslutet />}
      </>
    )
  )
}

export default ForetagResultat

import React from 'react'
import { FormikProps, withFormik } from 'formik'
import Gutter from '@local/Components/Gutter'
import {
  IAnslutnaForetagFormProps,
  IAnslutnaForetagFormValues,
} from '@local/Views/AnslutnaForetag/AnslutnaForetagForm/AnslutnaForetagForm.types'
import {
  anslutnaForetagValidationSchema,
  initialAnslutnaForetagValues,
} from '@local/Views/AnslutnaForetag/AnslutnaForetagForm/AnslutnaForetagForm.schema'
import { IAnslutnaForetagContent } from '@local/Views/AnslutnaForetag/AnslutnaForetag.types'
import Organisationsnummer from '@local/Views/AnslutnaForetag/AnslutnaForetagForm/Organisationsnummer'
import FormSubmit from '@local/Views/AnslutnaForetag/AnslutnaForetagForm/FormSubmit'
import ForetagResultat from '@local/Views/AnslutnaForetag/AnslutnaForetagForm/ForetagResultat'
import HTMLMapper from '@local/Components/HTMLMapper'
import { Grid2 as Grid } from '@mui/material'

const AnslutnaForetagForm: React.FC<
  IAnslutnaForetagFormProps & FormikProps<IAnslutnaForetagFormValues>
> = ({ content }) => (
  <>
    <HTMLMapper body={content.allaBolag.mainBody} />

    <Gutter offset_xs={32} />

    <Grid container>
      <Grid size={{ xs: 12, md: 7 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 9 }}>
            <Organisationsnummer />
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }}>
            <FormSubmit />
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <Gutter offset_xs={32} />

    <ForetagResultat />
  </>
)

const AnslutnaForetagFormik = withFormik<
  IAnslutnaForetagFormProps,
  IAnslutnaForetagFormValues
>({
  validateOnBlur: true,
  mapPropsToValues: () => initialAnslutnaForetagValues,
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ content }: { content: IAnslutnaForetagContent }) =>
    anslutnaForetagValidationSchema(content),
  displayName: 'AnslutnaForetagForm',
})(AnslutnaForetagForm)

export default AnslutnaForetagFormik

import React, { JSX } from 'react'
import { useFormikContext } from 'formik'
import { IAnslutnaForetagFormValues } from '@local/Views/AnslutnaForetag/AnslutnaForetagForm/AnslutnaForetagForm.types'
import { handleChangeWithTrim } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useAppDispatch } from '@local/Store/configureStore'
import { setShouldGetAnslutnaForetag } from '@local/Views/AnslutnaForetag/anslutnaForetagSlice'
import TextInput from '@local/Components/TextInput'
import { IAnslutnaForetagContent } from '@local/Views/AnslutnaForetag/AnslutnaForetag.types'

const Organisationsnummer = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const { sok } = usePickEpiContent<IAnslutnaForetagContent>()

  const formikContext = useFormikContext<IAnslutnaForetagFormValues>()

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setShouldGetAnslutnaForetag(false))
    handleChangeWithTrim(e, formikContext.handleChange)
  }

  return (
    <TextInput
      withTrim
      formikContext={formikContext}
      label={sok.heading}
      placeholder={sok.placeholder}
      name="organisationsnummer"
      onChange={handleOnChange}
    />
  )
}

export default Organisationsnummer

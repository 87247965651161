import { JSX } from 'react'
import { CircularProgress, styled } from '@mui/material'

const CenteredSpinner = styled('span')(() => ({
  display: 'block',
  textAlign: 'center',
}))

const Spinner = ({
  centered,
  size = 40,
}: {
  centered?: boolean
  size?: number
}): JSX.Element =>
  centered ? (
    <CenteredSpinner>
      <CircularProgress size={size} />
    </CenteredSpinner>
  ) : (
    <CircularProgress size={size} />
  )

export default Spinner
